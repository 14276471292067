<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['skinDryness'])">
      <single-answer-question
        title="label.skinDryness"
        :value="skinDryness"
        :options="$options.skinDrynessOptions"
        dysfunction="skin-dryness"
        @input="onFieldChange('skinDryness', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="considerableDryness"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['flakingSkin'])"
      >
        <single-answer-question
          id="flaking-skin"
          title="label.flakingSkin"
          :value="flakingSkin"
          :options="$options.yesNoResponseOptions"
          yes-no
          dysfunction="skin-dryness"
          @input="onFieldChange('flakingSkin', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  YES_NO_RESPONSE_OPTIONS,
  AMOUNT_OPTIONS
} from '@/modules/questionnaire/constants/questions';

export default {
  name: 'DrynessTemplate',
  components: { FadeInTransitionWrap, QuestionWithErrorWrap, SingleAnswerQuestion },
  skinDrynessOptions: AMOUNT_OPTIONS,
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    considerableDryness: {
      type: Boolean,
      default: false
    },
    skinDryness: {
      type: String,
      default: ''
    },
    flakingSkin: {
      type: String,
      default: ''
    }
  }
};
</script>
